<template>
	<div class="main_header">
		<div class="main_slider" style="background-image:url(/images/main.jpg)">
			<div class="container fill_height">
				<div class="row align-items-center fill_height">
					<div class="col">
						<div class="main_slider_content">
							<h1>
								{{ title }}
								<br />
								<small>{{ subtitle }}</small>
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SubHeader",
	props: {
		title: String,
		subtitle: String
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
	font-size: 3rem;
	color: #fff;
	line-height: 0.7;
	@include sp {
		font-size: 1.6rem;
	}
	small {
		font-size: 1rem;
		@include sp {
			font-size: 0.8rem;
		}
	}
}

.main_slider {
	height: 200px;
	/*@include tab {
		height: 160px;
	}*/
	@media only screen and (max-width: 1024px) {
		height: 10rem;
	}
}
.main_slider_content {
	text-align: left;
}
</style>