<template>
	<div class="tournament">
		<SubHeader
			title="JDO Tournament Prize Winner"
			subtitle="JDOトーナメント入賞者"
		/>

		<div class="container">
			<ul class="nav nav-pills mb-3" id="myTab" role="tablist">
				<li class="nav-item">
					<a class="nav-link" href="/jdo/tournament2024"> 2024年度 </a>
				</li>
				<li class="nav-item">
					<a class="nav-link active" href="javascript:void(0);"> 2023年度 </a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/jdo/tournament2022"> 2022年度 </a>
				</li>
			</ul>

			<div id="20240317_aichi" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2024.3.17 第19回 ADO Darts Open Tournament</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center offset-lg-2">
						<p>
							<span class="rank">メンズシングルス 準優勝</span>
							<img src="/images/jdo/2024aichi/aichi_01.jpg" class="img-fluid" />
							<span class="name">高山 得溶</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス 3位タイ</span>
							<img src="/images/jdo/2024aichi/aichi_02.jpg" class="img-fluid" />
							<span class="name">青木 まゆ</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20240225_ehime" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2024.2.25 OPEN DARTS TOUNAMENT IN EHIME 2024</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 3位タイ</span>
							<img src="/images/jdo/2024ehime/ehime_01.jpg" class="img-fluid" />
							<span class="name">柳瀬 将一</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス 3位タイ</span>
							<img src="/images/jdo/2024ehime/ehime_02.jpg" class="img-fluid" />
							<span class="name">青木 まゆ</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス 3位タイ</span>
							<img src="/images/jdo/2024ehime/ehime_03.jpg" class="img-fluid" />
							<span class="name">北村 智加</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20240218_kyoto" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2024.2.18 第18回 京都ダーツオープントーナメント2024</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 優勝</span>
							<img src="/images/jdo/2024kyoto/kyoto_04.jpg" class="img-fluid" />
							<span class="name">外筬 久人・黒川 智成</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 3位タイ</span>
							<img src="/images/jdo/2024kyoto/kyoto_05.jpg" class="img-fluid" />
							<span class="name">谷田 孝夫・高山 得溶</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 優勝</span>
							<img src="/images/jdo/2024kyoto/kyoto_01.jpg" class="img-fluid" />
							<span class="name">外筬 久人</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 準優勝</span>
							<img src="/images/jdo/2024kyoto/kyoto_02.jpg" class="img-fluid" />
							<span class="name">谷田 孝夫</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 3位タイ</span>
							<img src="/images/jdo/2024kyoto/kyoto_03.jpg" class="img-fluid" />
							<span class="name">磯和 勇希</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20231203_kagawa" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2023.12.3 香川ダーツオープントーナメント 2023</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center offset-lg-2">
						<p>
							<span class="rank">オープンダブルス 優勝</span>
							<img
								src="/images/jdo/2023kagawa/kagawa_01.jpg"
								class="img-fluid"
							/>
							<span class="name">大本 直樹・高山 得溶</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 準優勝</span>
							<img
								src="/images/jdo/2023kagawa/kagawa_02.jpg"
								class="img-fluid"
							/>
							<span class="name">高山 得溶</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20231112_kanagawa" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2023.11.12 第23回AKDOオープンダーツトーナメント</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-6 text-center offset-lg-4">
						<p>
							<span class="rank">レディースシングルス 準優勝</span>
							<img
								src="/images/jdo/2023kanagawa/kanagawa_01.jpg"
								class="img-fluid"
							/>
							<span class="name">青木 まゆ</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20230917_okayama" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2023.7.16 OKAYAMA OPEN DARTS TOURNAMENT 2023</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center offset-lg-2">
						<p>
							<span class="rank">レディースシングルス 3位タイ</span>
							<img src="/images/jdo/noimage_700x600.jpg" class="img-fluid" />
							<span class="name">青木 まゆ</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 準優勝</span>
							<img
								src="/images/jdo/2023okayama/okayama_01.jpg"
								class="img-fluid"
							/>
							<span class="name">中井 涼</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20230716_hyogo" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2023.7.16 第32回 H.D.O.オープンダーツトーナメント大会</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 優勝</span>
							<img src="/images/jdo/2023hyogo/hyogo_01.jpg" class="img-fluid" />
							<span class="name">谷田 孝夫</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 準優勝</span>
							<img src="/images/jdo/2023hyogo/hyogo_02.jpg" class="img-fluid" />
							<span class="name">外筬 久人</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 3位タイ</span>
							<img src="/images/jdo/2023hyogo/hyogo_03.jpg" class="img-fluid" />
							<span class="name">松本 康寿</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 3位タイ</span>
							<img src="/images/jdo/2023hyogo/hyogo_04.jpg" class="img-fluid" />
							<span class="name">柳瀬 将一</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス 優勝</span>
							<img src="/images/jdo/2023hyogo/hyogo_05.jpg" class="img-fluid" />
							<span class="name">青木 まゆ</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス 3位タイ</span>
							<img src="/images/jdo/2023hyogo/hyogo_06.jpg" class="img-fluid" />
							<span class="name">北村 智加</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20230715_jdomasters" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2023.7.15 JDOマスターズ</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center offset-lg-2">
						<p>
							<span class="rank">メンズマスターズ G2 3位タイ</span>
							<img src="/images/jdo/noimage_700x600.jpg" class="img-fluid" />
							<span class="name">工藤 光生</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス G1 優勝</span>
							<img
								src="/images/jdo/2023masters/jdomasters_01.jpg"
								class="img-fluid"
							/>
							<span class="name">青木 まゆ</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20230521_nara" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2023.5.21 第3回 奈良ダーツオープントーナメント 2023</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 優勝</span>
							<img src="/images/jdo/2023nara/nara_01.jpg" class="img-fluid" />
							<span class="name">高山 得溶・谷田 孝夫</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 3位タイ</span>
							<img src="/images/jdo/2023nara/nara_02.jpg" class="img-fluid" />
							<span class="name">藤末 祐史・磯和 勇希</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 3位タイ</span>
							<img src="/images/jdo/2023nara/nara_03.jpg" class="img-fluid" />
							<span class="name">外筬 久人・黒川 智成</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス 3位タイ</span>
							<img src="/images/jdo/2023nara/nara_04.jpg" class="img-fluid" />
							<span class="name">北村 智加</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 優勝</span>
							<img src="/images/jdo/2023nara/nara_05.jpg" class="img-fluid" />
							<span class="name">高山 得溶</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20230423_kochi" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2023.4.23 KOCHI OPEN DARTS TOURNAMENT vol.13</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center offset-lg-2">
						<p>
							<span class="rank">オープンダブルス 優勝</span>
							<img src="/images/jdo/2023kochi/kochi_01.jpg" class="img-fluid" />
							<span class="name">高山 得溶・大本 直樹</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス 準優勝</span>
							<img src="/images/jdo/2023kochi/kochi_02.jpg" class="img-fluid" />
							<span class="name">青木 まゆ</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "@/components/common/SubHeader.vue";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
	name: "tournament2020",
	components: {
		SubHeader,
	},
	data() {
		return {};
	},
	mounted: function () {},
	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}
h4 {
	border-bottom: solid 3px #333;
	padding-bottom: 5px;
	margin-bottom: 2rem;
	position: relative;
	&:before {
		content: "";
		width: 3rem;
		height: 3px;
		background-color: $primary-color;
		display: block;
		position: absolute;
		left: 0;
		bottom: -3px;
	}
}
a {
	text-decoration: underline;
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
.btn-download {
	font-size: 1rem;
	color: #333;
	border: none;
	margin-top: 1rem;
	background: $primary-color;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #333;
		background-color: $secondary-color;
	}
	&:active {
		color: #333;
		background-color: $secondary-color;
	}
}
.rank,
.rank_winner,
.rank_2nd,
.rank_3rd,
.name {
	color: #333;
}
.rank_winner {
	font-size: 2rem;
	i {
		color: #dbb400;
	}
}
.rank_2nd {
	font-size: 1.4rem;
	i {
		color: #9fa0a0;
	}
}
.rank_3rd {
	font-size: 1.4rem;
	i {
		color: #c47022;
	}
}
i {
	margin-right: 0.6rem;
}
</style>
